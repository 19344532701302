import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"

// Disable Dropzone auto discover
Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ["input", "preview", "dropzone", "previewTemplate"]
  static values = {
    maxFiles: { type: Number, default: 10 },
    maxFileSize: { type: Number, default: 10 }, // MB
    acceptedFiles: { type: String, default: "" },
    dragHoverClass: { type: String, default: "bg-gray-100" } // New value for hover class
  }

  connect() {
    const controller = this
    const previewTemplate = this.previewTemplateTarget.innerHTML

    this.dropzone = new Dropzone(this.dropzoneTarget, {
      url: "/", // Dummy URL since we're not uploading directly
      autoProcessQueue: false, // Prevent automatic upload
      uploadMultiple: true,
      addRemoveLinks: false, // We're using our own remove button
      previewsContainer: this.hasPreviewTarget ? this.previewTarget : null,
      previewTemplate: previewTemplate,
      clickable: [this.dropzoneTarget, this.dropzoneTarget.querySelector('div')], // Make the entire dropzone and inner div clickable
      init: function() {
        this.on("addedfile", function(file) {
          controller._updateHiddenInput()
        })

        this.on("removedfile", function(file) {
          controller._updateHiddenInput()
        })

        this.on("error", function(file, message) {
          if (file.previewElement) {
            let messageElement = file.previewElement.querySelector('[data-dz-errormessage]')
            if (messageElement) {
              messageElement.parentElement.classList.add('text-red-500', 'text-xs', 'mt-1', 'pl-4')
            }
          }
        })

        this.on("dragenter", function() {
          controller._toggleHoverClasses(true)
        })

        this.on("dragleave", function() {
          controller._toggleHoverClasses(false)
        })

        this.on("drop", function() {
          controller._toggleHoverClasses(false)
        })
      }
    })
  }

  disconnect() {
    this.dropzone.destroy()
  }

  _updateHiddenInput() {
    this.inputTarget.value = ''

    const files = this.dropzone.files
    if (files.length === 0) return

    const dataTransfer = new DataTransfer()
    files.forEach(file => {
      dataTransfer.items.add(file)
    })

    this.inputTarget.files = dataTransfer.files

    console.log("Files", this.inputTarget.files)
  }

  _toggleHoverClasses(add) {
    const classes = this.dragHoverClassValue.split(' ')
    classes.forEach(cls => {
      if (add) {
        this.dropzoneTarget.classList.add(cls)
      } else {
        this.dropzoneTarget.classList.remove(cls)
      }
    })
  }
}
